.appContainer {
  font-family: Avenir, Helvetica, Sans-serif;
  width: 100%;
  max-width: 100vw;
  background-color: rgba(255, 255, 255, 0.92);
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 0px 10px #dddddd;
  padding: 1.5%;
  box-sizing: border-box;
  min-height: 400px;
}

.rightPanelContainer {
  display: inline-block;
  vertical-align: top;
  border: 1px solid grey;
}

.rightPanelContent {
  padding: 12px;
}

.rightPanelContent p {
  margin: 0;
  font-size: 0.85em;
}

.illustration {
  float: left;
  margin-right: 10px; 
  margin-bottom: 10px;
  width: 150px;
}

.highlight-text {
  background-color: #fbfbb8;
}

.instructionText {
  color: grey;
  font-weight: bold;
  margin-bottom: 8px;
}

.questionText {
  font-size: 1.1em;
  margin-top: 0;
  margin-bottom: 0px;
}

ol {
  padding: 0;
  list-style-type: none;
}

li {
  padding: 7.5px 15px;
  box-shadow: 0px 0px 10px #dddddd;
  margin-bottom: 12px;
  list-style-position: inside;
  padding-right: 20px;
}

.answerDetail {
  padding-left: 0;
  padding-right: 25px;
}

.buttonContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
}

.correctAnswer {
  color: #138a13;
}

.incorrectAnswer {
  color: #bd0101;
}

.footer {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.scoreBoard {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.smallerFont {
  font-size: 0.9em;
}

.smallestFont {
  font-size: 0.8em;
}

.footerContainer {
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footerLabel {
  color: grey;
  font-size: 0.7em;
  margin: 0;
}

.scoreDisplay {
  font-size: 1.8em;
  font-weight: bold;
  margin: 0px 0px 0px 12px;
}

.lastScreenContainer {
  font-weight: normal;
  text-align: center;
  padding-left: 20%;
  padding-right: 20%;
}

.firstScreenContainer {
  font-weight: normal;
  text-align: center;
  padding: 5%;
  box-shadow: 0px 0px 10px #dddddd;
  margin: 1%;
}

.fontWeightNormal {
  font-weight: normal;
}

a.trialLink:visited, a.trialLink:link {
  color: black;
  font-weight: bold;
}

/* MOBILE */

@media (max-width: 750px) {
  .appContainer {
    width: 100%;
    max-width: 100vw;
    padding: 5%;
    margin: 0;
    min-height: 100vh;
  }

  .scoreBoard {
    margin-bottom: 10px;
  }

  .quizContainer {
    padding-bottom: 135px;
  }

  .footer {
    text-align: center;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    background-color: white;
    box-shadow: 0px 0px 10px #c1c1c1;
    margin: 0;
    padding-top: 15px;
  }

  .footerLabel {
    display: inline-block;
    margin-right: 20px;
    width: 75px;
  }

  .scoreDisplay {
    font-size: 1.2em;
  }

  .footerContainer {
    padding-left: 10px;
    padding-right: 10px;
  }

  .buttonContainer {
    width: 100%;
  }
}
